import * as React from "react"
import DocumentationContent from "../../components/DocumentationContent"
import DocumentationSidebar from "../../components/DocumentationSidebar"
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import {FullSlideshowLightboxExample, SlideshowLightboxExampleDoc, SlideshowLightboxExample, 
  PropsDoc, ThemesDoc} from "../../components/Examples";
import Content, { HTMLContent } from '../../components/Content'
import Documentation from '../../components/Documentation'
import {SlideshowLightbox, Image} from "lightbox.js-react";
import { CopyBlock, CodeBlock, atomOneDark, solarizedDark, xt256 } from "react-code-blocks";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

export default function ReactImageZoomGuide () {
  const images = [{title: "Photon", src: "https://source.unsplash.com/600x400/?pastel+cloud"}, 
  {title: "Inversion", src: "https://source.unsplash.com/500x400/?pastel+clouds"},
  {title: "Legion", src: "https://source.unsplash.com/600x400/?pastel+sky"},
  {title: "Inversion", src: "https://source.unsplash.com/600x400/?pastel"},
  {title: "Twenty Neue", src: "https://source.unsplash.com/600x400/?sky+pastel"},
  {title: "Geometrik", src: "https://source.unsplash.com/600x400/?dreamy+clouds"},         
  {title: "Achtung", src: "https://source.unsplash.com/600x400/?pastel+fruit"},];
  const showLineNumbers = true;
  const wrapLines = true;
  const codeBlock = false;

  return <Layout>
  <Header />
  <section className="section section--gradient font-inter bg_white">
  
      <div className="pt-4">
      <main className="flex-grow">

          <section>
          <div className="max-w-6xl mx-auto px-2 sm:px-2">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Main content */}
              <div className="md:flex md:justify-between" data-sticky-container>

                  <DocumentationSidebar />

                  <div className="md:flex-grow documentation font-inter">

                    {/* <Header /> */}
                    <div className="text-lg text-gray-600 pt-8 mt-4">
                      <h1 className="h1 text-gray-900 tracking-tight leading-tight w-4/5 mb-4 font-bold">Adding React Image Zoom Functionality 
                      with Lightbox.js</h1>

                      <p className="mb-8">
                      Welcome! In this guide, I'll be showing you how you can add React image zoom functionality to your website, web app or 
                      React project.
                      </p>

                      {/* <h3 id="preview" className="h3 text-gray-900 font-bold text-3xl" style={{ scrollMarginTop: '100px' }}>Preview</h3>
                      <p className="mb-8">
                      Here's a quick preview of what we'll be creating in this tutorial:
                      </p> */}


                    <h3 id="import" className="h3 text-gray-900 font-bold text-3xl" style={{ scrollMarginTop: '100px' }}>Import</h3>

                    <p>
                    Firstly, import the components required as well as the CSS file for this library:
                    </p>

                    <div className="mb-8">
                        <SyntaxHighlighter language="jsx" style={oneDark}  className="mb-8 codeBlock">
                        {`import 'lightbox.js-react/dist/index.css'\nimport {SlideshowLightbox} from 'lightbox.js-react'`}
                        </SyntaxHighlighter>
                    </div>

                    <h4 className="h4 text-gray-900 ">Adding a license key</h4>

                    <p>Next, a license key will need to be initialized in order to use Lightbox.js. If you require a license key
                      you can purchase an individual or team license <a className="text-underline text-blue-600" href="/pricing">here</a>,
                      but if your project is open-source, just <a className="text-underline text-blue-600" href="/contact/">contact us</a> to get a free 
                      license key. </p>

                      <p className="flex mt-8 items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
                                <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
                                </svg>
                                <span>If you wish to purchase a license, you can get a 20% off discount with the coupon code OFFER20 at checkout.</span>
                    </p>            


                      <p>The license key can be initialized like so:</p>

                      <SyntaxHighlighter language="jsx" style={oneDark}  className="mb-12 codeBlock">
                      {`useEffect(() => {
    initLightboxJS("Insert License key", "Insert plan type here");
});`}
                      </SyntaxHighlighter>

                    <p>
                      The two plan types are <code>individual</code> and <code>team</code>.
                    </p>

                    <h3 id="usage" className="text-gray-900 mb-4 text-3xl font-semibold" style={{ scrollMarginTop: '100px' }}>Usage</h3>

                    <p>
                    To initialize the lightbox, include the images you wish to add to the lightbox within a `SlideshowLightbox`   
                     component as shown below. 
                    </p>

                    <p>
                        In this example, we'll be adding a night theme to the lightbox also, along with rounded images.
                    </p>
                    
                    <div className="mb-8">
                        <SyntaxHighlighter language="jsx" style={oneDark} className="mb-8 codeBlock">
                        {`<SlideshowLightbox theme="night" showThumbnails={true} roundedImages={true} 
className="container grid grid-cols-3 gap-2 mx-auto"> 
    <img className="w-full rounded" src={"https://images.pexels.com/photos/1470405/pexels-photo-1470405.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} />
    <img className="w-full rounded object-cover" src={"https://images.pexels.com/photos/2693282/pexels-photo-2693282.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} />  
    <img className="w-full rounded object-cover" src={"https://images.pexels.com/photos/315191/pexels-photo-315191.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} />
    <img className="w-full rounded" src={"https://images.pexels.com/photos/2303337/pexels-photo-2303337.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} />       

    <img className="w-full rounded" src={"https://images.pexels.com/photos/2339009/pexels-photo-2339009.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} />
    <img className="w-full rounded" src={"https://images.pexels.com/photos/2434627/pexels-photo-2434627.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} />   
                                      
</SlideshowLightbox>`}
                        </SyntaxHighlighter>
                    </div>

                    <p>You'll find a live preview of the demo below, try clicking on the images to open the lightbox.</p>
                    <SlideshowLightbox theme="night" showThumbnails={true} roundedImages={true} 
                        className="container grid grid-cols-3 gap-2 mx-auto"> 
                            <img className="w-full rounded" src={"https://images.pexels.com/photos/1470405/pexels-photo-1470405.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} />
                            <img className="w-full rounded object-cover" src={"https://images.pexels.com/photos/2693282/pexels-photo-2693282.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} />  
                            <img className="w-full rounded object-cover" src={"https://images.pexels.com/photos/315191/pexels-photo-315191.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} />
                            <img className="w-full rounded" src={"https://images.pexels.com/photos/2303337/pexels-photo-2303337.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} />       

                            <img className="w-full rounded" src={"https://images.pexels.com/photos/2339009/pexels-photo-2339009.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} />
                            <img className="w-full rounded" src={"https://images.pexels.com/photos/2434627/pexels-photo-2434627.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} />   
                                                            
                        </SlideshowLightbox>

                    <h3 className="text-gray-900 mt-12">Zooming into Images</h3>
                    <p>You can zoom into images by clicking on the magnification icons in the controls, or by double-clicking on the image.
                        Plus image panning is also supported, so once you've zoomed into an image, you can move around the image also
                        by dragging the image.
                    </p>
                    <p>If you're on a mobile device, images can be magnified through the pinch-to-zoom mobile gesture.</p>


                    <h3 className="text-gray-900 mt-12">Further Customization</h3>

                    <p>To customize the lightbox and theme further, be sure to take a look at the <Link 
                    className="text-blue-600 hover:underline"  href="/docs/SlideshowLightbox">Slideshow 
                    Lightbox documentation for more details.</Link></p>

                    <p className="flex mt-8 items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
                                <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
                                </svg>
                                <span>Check out the <Link className="text-blue-600 hover:underline" href="/docs/SlideshowLightbox">Slideshow Lightbox documentation</Link> for more details on customization options, plus code samples too.</span>
                    </p>     

      

    </div>

    {/* Related content */}
    <div className="mt-8">
      <h3 className="h3 mb-8 font-semibold">Related</h3>
      <Link className="flex justify-between items-center p-4 rounded border border-gray-200 
      transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" to="/docs/Image">
        <div>
          <div className="text-normal font-medium mb-1">Image component</div>
          <div className="text-sm text-gray-600">If you'd prefer to display a single image within a lightbox, be sure 
          to check out the Image component.</div>
        </div>
        <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </Link>
      <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition 
      duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="https://github.com/silvia-odwyer/lightbox.js">
        <div>
          <div className="text-normal font-medium mb-1">Feature request</div>
          <div className="text-sm text-gray-600">Want to request a new feature? Be sure to open an issue in our GitHub repository!</div>
        </div>
        <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </a>
      <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
    </div>

    {/* <Feedback /> */}

  </div>

              </div>

              </div>
          </div>
          </section>

          </main>
      </div>
          
  </section>
    <Footer overrideDarkTheme={true} />
  </Layout>
  
}